import React, { useEffect, useState } from "react";
import api from "../../api/axios";
import Input from "../ui/Input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ExportProduction = () => {
  const [modeles, setModeles] = useState([]);
  const [modeleSelectionne, setModeleSelectionne] = useState("");
  const [production, setProduction] = useState(0);
  const [productionTotale, setProductionTotale] = useState(0);
  const [encore, setEncore] = useState(0);
  const [entre, setEntre] = useState(0);
  const [dateSelectionnee, setDateSelectionnee] = useState(null);
  const [boutonDesactive, setBoutonDesactive] = useState(false);
  const [anciensExports, setAnciensExports] = useState([]);

  useEffect(() => {
    const fetchModeles = async () => {
      try {
        const response = await api.get("/models");
        setModeles(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des modèles :", error);
      }
    };

    fetchModeles();
  }, []);

  useEffect(() => {
    const fetchExportProduction = async () => {
      if (modeleSelectionne) {
        try {
          const responseControl = await api.get(
            `/magasin_production/${modeleSelectionne}`
          );
          setEntre(responseControl.data.value);
        } catch (error) {
          console.error("Erreur lors de la récupération des données de contrôle :", error);
        }

        try {
          const response = await api.get(`/export/${modeleSelectionne}`);
          setProductionTotale(response.data.total);
          setAnciensExports(response.data.exports); // Stocker les anciens exports
        } catch (error) {
          console.error("Erreur lors de la récupération des données d'ExportProduction :", error);
          setEncore(entre);
        }
      }
    };

    fetchExportProduction();
    setEncore(entre - productionTotale);
  }, [modeleSelectionne, entre, productionTotale]);

  const handleModeleChange = (e) => {
    setModeleSelectionne(e.target.value);
  };

  const handleDateChange = (date) => {
    setDateSelectionnee(date);
  };

  const onChange = (name, value) => {
    const nouvelleValeur = parseInt(value, 10) || 0;
    const nouvelEncore = entre - nouvelleValeur;

    if (nouvelEncore < 0) {
      toast.error("Encore ne peut pas être inférieur à 0");
      return;
    }

    setProduction(nouvelleValeur);
    setEncore(nouvelEncore);
  };

  const handleSave = async () => {
    if (encore < 0) {
      toast.error("Encore ne peut pas être inférieur à 0");
      return;
    }

    if (production == null) {
      toast.error("La valeur de la production est requise");
      return;
    }

    if (dateSelectionnee == null) {
      toast.error("La date est requise");
      return;
    }

    setBoutonDesactive(true);

    try {
      await api.post(`/export/${modeleSelectionne}`, {
        value: production,
        date: dateSelectionnee ? dateSelectionnee.toISOString().split("T")[0] : null,
      });
      toast.success("Données enregistrées avec succès");
    } catch (error) {
      console.error("Erreur lors de la mise à jour des données d'ExportProduction :", error);
      toast.error("Échec de la mise à jour des données de production.");
    } finally {
      setBoutonDesactive(false);
    }
  };

  const modeleSelectionneData = modeles.find((modele) => modele.id == modeleSelectionne);

  return (
    <div className="ml-[19%] pt-[6rem]">
      <ToastContainer />
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold">Exportation</h2>
        <div className="ml-7 mb-4 pr-6">
          <select
            className="block w-full mt-4 outline-0 p-[.5rem] border border-[#b3b3b3] focus:border-2 focus:border-[#2684ff] rounded"
            value={modeleSelectionne}
            onChange={handleModeleChange}
          >
            <option value="">Sélectionnez un modèle</option>
            {modeles.map((modele) => (
              <option key={modele.id} value={modele.id}>
                {modele.modele}
              </option>
            ))}
          </select>
        </div>
      </div>
      {modeleSelectionneData && (
        <div className="w-full pr-6">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold">{modeleSelectionneData.category}</h3>
            <h3 className="font-semibold">{modeleSelectionneData.client}</h3>
          </div>
        </div>
      )}
      <div className="w-full pr-6">
        {modeleSelectionneData && (
          <div className="flex items-center justify-between">
            <h3 className="font-semibold">Encore : {encore}</h3>
          </div>
        )}
      </div>
      {modeleSelectionne && (
        <>
          <div className="flex justify-between items-center mx-auto mt-[2rem] mb-4 shadow-md max-w-[500px] p-5 rounded border">
            <div>
              <h3 className="font-semibold">Production</h3>
              <Input
                handleChange={(name, value) => onChange("production", value)}
                name={"production"}
                placeholder="Production totale"
                text={production ? production.toString() : "0"}
              />
            </div>
            <div className="font-semibold ml-5">
              <label className="block">Date d'exportation</label>
              <DatePicker
                selected={dateSelectionnee}
                onChange={handleDateChange}
                dateFormat="yyyy-MM-dd"
                className="outline-0 p-[.5rem] border border-[#b3b3b3] focus:border-2 focus:border-[#2684ff] rounded mt-4"
              />
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleSave}
              disabled={boutonDesactive}
            >
              Enregistrer
            </button>
          </div>
          <div className="max-h-60 overflow-y-scroll border p-4 mt-4 rounded">
            <h3 className="font-semibold mb-2">Exports précédents</h3>
            <ul>
              {anciensExports.map((exportItem, index) => (
                <li key={index} className="mb-2 border-b pb-2">
                  <p>Date : {new Date(exportItem.date).toLocaleDateString()}</p>
                  <p>Valeur : {exportItem.value}</p>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default ExportProduction;

