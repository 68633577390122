import React, { useState, useEffect } from 'react';
import api from "../../api/axios";

const Taux = () => {
  const [retouches, setRetouches] = useState([]);
  const [models, setModels] = useState([]);
  const [chains, setChains] = useState([]);
  const [posts, setPosts] = useState([]);
  const [totalExport, setTotalExport] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedChain, setSelectedChain] = useState('');

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const modelsResponse = await api.get("/models");
        setModels(modelsResponse.data);
      } catch (error) {
        console.error('Error fetching models:', error);
      }
    };

    const fetchChains = async () => {
      try {
        const response = await api.get("/chains");
        setChains(response.data);
      } catch (error) {
        console.error("Error fetching chains:", error);
      }
    };

    const fetchPosts = async () => {
      try {
        const response = await api.get("/posts");
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchChains();
    fetchModels();
    fetchPosts();
  }, []);

  useEffect(() => {
    const fetchRetouchData = async () => {
      try {
        const response = await api.get('/retouch');
        setRetouches(response.data);
      } catch (error) {
        console.error('Error fetching retouch data:', error);
      }
    };

    const getExport = async (modelId) => {
      try {
        const response = await api.get(`/export/${modelId}`);
        const exportData = response.data;
        setTotalExport(exportData.total);
      } catch (error) {
        console.error('Error fetching export data:', error);
      }
    };

    if (selectedModel) {
      fetchRetouchData();
      getExport(selectedModel);
    }
  }, [selectedModel]);

  useEffect(() => {
    filterData();
  }, [selectedModel, selectedChain, retouches]);

  const filterData = () => {
    let filtered = retouches;

    if (selectedModel) {
      filtered = filtered.filter(item => item.model.id == selectedModel);
    }

    if (selectedChain) {
      filtered = filtered.filter(item => item.chain && item.chain.id == selectedChain);
    }

    setFilteredData(filtered);
  };

  const calculatePercentage = (controlFinal, total) => {
    return total > 0 ? ((controlFinal / total) * 100).toFixed(1) + '%' : '0%';
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <h2 className="ml-7 mb-9 text-2xl font-semibold">
          Retouch Data Table
        </h2>

        <div  className="flex items-center justify-between">
          <select 
            className="block ml-7 mb-4  w-fit mt-4 outline-0 p-[.5rem] border border-[#b3b3b3] focus:border-2 focus:border-[#2684ff] rounded"
            onChange={e => setSelectedModel(e.target.value)} value={selectedModel}>
            <option value="">Select Model</option>
            {models.map(model => (
              <option key={model.id} value={model.id}>
                {model.modele}
              </option>
            ))}
          </select>

          <select 
            className="block ml-7 mb-4 w-fit mt-4 outline-0 p-[.5rem] border border-[#b3b3b3] focus:border-2 focus:border-[#2684ff] rounded"
            onChange={e => setSelectedChain(e.target.value)} value={selectedChain}>
            <option value="">Select Chain (Sortie)</option>
            {chains.map(chain => (
              <option key={chain.id} value={chain.id}>
                {chain.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <table  style={{width: '96%'}} className="ml-7  bg-slate-200 mt-5">
        <thead>
          <tr>
            <th className="px-6 text-center py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider min-w-[150px]">Post</th>
            <th className="px-6 text-center py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider min-w-[150px]">Info Type</th>
            <th className="px-6 text-center py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider min-w-[150px]">Controle Table</th>
            <th className="px-6 text-center py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider min-w-[150px]">Controle Final</th>
          </tr>
        </thead>
        <tbody>
      {posts.map(post => {
                 const postRetouchData = filteredData.filter(item => item.post && item.post.id == post.id);
            const totalRetouch = postRetouchData.reduce((sum, item) => sum + item.value, 0);
 return (
              <React.Fragment key={post.id}>
                <tr className="bg-gray-50">
                  <td className="p-[2rem] text-center text-[#4E4A4A] font-semibold min-w-[150px]" rowSpan="2">{post.name}</td>
                  <td className="p-[2rem] text-center text-[#4E4A4A] font-semibold min-w-[150px]">NBR</td>
                  <td className="p-[2rem] text-center text-[#4E4A4A] font-semibold min-w-[150px]">{selectedChain ? totalRetouch : ''}</td>
                  <td className="p-[2rem] text-center text-[#4E4A4A] font-semibold min-w-[150px]">{!selectedChain ? totalRetouch : ''}</td>
                </tr>
                <tr>
                  <td className="p-[2rem] text-center text-[#4E4A4A] font-semibold min-w-[150px]">%</td>
                  <td className="p-[2rem] text-center text-[#4E4A4A] font-semibold min-w-[150px]">{selectedChain ? calculatePercentage(totalRetouch, totalExport) : ''}</td>
                  <td className="p-[2rem] text-center text-[#4E4A4A] font-semibold min-w-[150px]">{!selectedChain ? calculatePercentage(totalRetouch, totalExport) : ''}</td>
                </tr>              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Taux;
